import { gql, useQuery } from "@apollo/client";
import _get from "lodash/get";


export const ACCOUNT_PROFILE = gql`
    query profile {
		profile {
			id
			aspNetUserId
			code
			fullName
			firstName
			lastName
			avatar
			email
			contactTypeCode
			roles {
				id
				name
				normalizedName
			}
			account {
				id
				name
				balance {
					id
					displayName
					balance
					sort
				}
			}
		}
	}
`;

export const useAccountProfile = () => {
	const { data, loading, error } = useQuery(ACCOUNT_PROFILE);

	const profile = _get(data, 'profile', []);

	return { data: profile, loading, error };
};
