import React from 'react';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

/**
 * The base message component, used by other components to display the message ui
 * @prop {Function} variant The message variant (determines what buttons can show)
 * @prop {Function} severity The severity of the message (determines the message style)
 * @prop {Function} handleOk function to be called when Ok is pressed
 * @prop {Function} handleYes function to be called when yes is pressed
 * @prop {Function} handleNo function to be called when no is pressed
 */
interface Props {
  variant: 'alert' | 'confirmation' | 'error';
  severity: 'error' | 'warning' | 'info' | 'success';
  handleOk?: Function;
  handleYes?: Function;
  handleNo?: Function;
}

const AlertMessage: React.FC<Props> = (props) => {
  const classes = useStyles();

  // const [highAlertMessages, setHighAlertMessages] = useState([]);

  const handleOk = () => {
    if (props.handleOk) {
      props.handleOk();
    }
  };

  const handleYes = () => {
    if (props.handleYes) {
      props.handleYes();
    }
  };

  const handleNo = () => {
    if (props.handleNo) {
      props.handleNo();
    }
  };

  const getButtonClass = () => {
    switch (props.severity) {
      case 'error':
        return classes.errorButton;
      case 'warning':
        return classes.warningButton;
      case 'info':
        return classes.infoButton;
      case 'success':
        return classes.successButton;
    }
  };
  const getRootClass = () => {
    switch (props.severity) {
      case 'error':
        return classes.errorRoot;
      case 'warning':
        return classes.warningRoot;
      case 'info':
        return classes.infoRoot;
      case 'success':
        return classes.successRoot;
    }
  };

  return (
    // <Box className={classes.root}>
    //     MESSAGE
    // </Box>
    <ClickAwayListener onClickAway={handleNo}>
      <Alert
        className={`${classes.root} ${getRootClass()}`}
        severity={props.severity}
      >
        <p className={classes.messageText}>{props.children}</p>
        {(props.variant === 'alert' || props.variant === 'error') &&
        props.handleOk ? (
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              className={`${classes.button} ${getButtonClass()}`}
              onClick={handleOk}
            >
              Ok
            </Button>
          </div>
        ) : (
          ''
        )}
        {props.variant === 'confirmation' &&
        props.handleYes &&
        props.handleNo ? (
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              className={`${classes.button} ${
                classes.marginButton
              } ${getButtonClass()}`}
              onClick={handleYes}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              className={`${classes.button} ${getButtonClass()}`}
              onClick={handleNo}
            >
              No
            </Button>
          </div>
        ) : (
          ''
        )}
      </Alert>
    </ClickAwayListener>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
    [theme.breakpoints.up('md')]: {
      minWidth: '500px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  messageText: {
    marginTop: 0,
    marginBottom: 0,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: 'calc(100% + 1em + 12px))',
    fontSize: '22px',
    marginLeft: 'calc(-1em - 12px)',
    marginTop: theme.spacing(2),
  },
  button: {},
  marginButton: {
    marginRight: theme.spacing(2),
  },
  errorButton: {
    backgroundColor: theme.palette.error.light,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  warningButton: {
    backgroundColor: theme.palette.warning.light,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
    },
  },
  infoButton: {
    backgroundColor: theme.palette.info.light,
    '&:hover': {
      backgroundColor: theme.palette.info.main,
    },
  },
  successButton: {
    backgroundColor: theme.palette.success.light,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
  errorRoot: {
    borderColor: theme.palette.error.main,
  },
  warningRoot: {
    borderColor: theme.palette.warning.main,
  },
  infoRoot: {
    borderColor: theme.palette.info.main,
  },
  successRoot: {
    borderColor: theme.palette.success.main,
  },
}));

export default AlertMessage;
