import React from 'react';
import { useHistory } from 'react-router';
import {
  SwipeableDrawer,
  List,
  IconButton,
  makeStyles,
  ListItem,
  ListItemText,
} from '@material-ui/core';

import { MenuNode } from 'interfaces';
import CloseIcon from '@material-ui/icons/Close';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import MobileMenuChild from './MobileMenuChild';
import { useNavigationDispatch, useNavigationState } from './navigationContext';
import { useTranslation } from 'react-i18next';

interface Props {
  menuJson: MenuNode[] | null;
  isOpen: boolean;
}

/**
 * Handles the mobile swipable menu
 * @param param0 Props for component
 */
const MobileMenu: React.FC<Props> = ({ menuJson, isOpen }) => {
  const classes = useStyles();
  const navigationDispatch = useNavigationDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const toggleMobileMenu = (state: boolean) => {
    if (state) {
      navigationDispatch({ type: 'showMobileMenu' });
    } else {
      navigationDispatch({ type: 'hideMobileMenu' });
    }
  };
  return (
    <SwipeableDrawer
      open={isOpen}
      onClose={() => toggleMobileMenu(false)}
      onOpen={() => toggleMobileMenu(true)}
      anchor="left"
      className={classes.menuDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <IconButton
        aria-label="menu close"
        onClick={() => toggleMobileMenu(false)}
        children={<CloseIcon />}
        size="medium"
        className={classes.closeIcon}
      />
      <List className={classes.menuPane}>
        {menuJson?.map((node) => {
          if (node.parentId === menuJson[0].id) {
            return (
              <MobileMenuChild
                id={node.id}
                menuNode={node}
                key={node.id}
                tier={1}
                menuJson={menuJson}
              />
            );
          }
        })}

        <ListItem 
          button 
          onClick={(e: any) => {
            history.push(`/available-inventory`)
            toggleMobileMenu(false);
        }}>
          <ListItemText primary={t("availableInventory.text")} />
        </ListItem>
      </List>
    </SwipeableDrawer>
  );
};

const useStyles = makeStyles((theme) => ({
  menuDrawer: {
    width: '80%',
    flexShrink: 0,
  },
  drawerPaper: {
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: 0,
    },
  },
  menuPane: {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
  },
  closeIcon: {
    display: 'inline-block',
    width: '50px',
    marginLeft: 'auto',
  },
}));

export const MobileMenuToggle = () => {
  const navigationDispatch = useNavigationDispatch();
  const navigationState = useNavigationState();

  const toggleMobileMenu = () => {
    if (navigationState.isMobileMenuOpen) {
      navigationDispatch({ type: 'hideMobileMenu' });
    } else {
      navigationDispatch({ type: 'showMobileMenu' });
    }
  };
  return (
    <Hidden mdUp>
      <IconButton aria-label="menu" onClick={() => toggleMobileMenu()}>
        <MenuIcon />
      </IconButton>
    </Hidden>
  );
};
export default MobileMenu;
