import { gql } from '@apollo/client';

export const addressFragment = gql`
  fragment AddressFragment on Address {
    id
    firstName
    lastName
    name
    address1
    address2
    city
    stateId
    countryId
    stateName
    countryName
    postalCode
    phoneNumber
    isPrimary
    accountId
    addressType {
      name 
      displayName
    }
  }
`;
