import { gql, useMutation } from '@apollo/client';
import { cartLinesFragment } from '../fragments/cartLines';

import * as deleteCartLineTypes from './__generated__/deleteCartLine';

// TODO: Convert selections to qgl fragment as we are using it also in queries/getCart
export const DELETE_CART_LINE = gql`
  mutation deleteCartLine($input: DeleteCartLineInput!) {
    deleteCartLine(input: $input) {
      id
      subtotal
      itemCount
      total
      taxTotal
      freightTotal
      lines {
        ...CartLinesFragment
      }
    }
  }
  ${cartLinesFragment}
`;

export const useDeleteCartLine = () => {
  const [mutate, { data, error }] = useMutation<
    deleteCartLineTypes.deleteCartLine,
    deleteCartLineTypes.deleteCartLineVariables
  >(DELETE_CART_LINE);

  return { mutate, data, error };
};
