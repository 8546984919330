import {LinkedAccount} from "../../operations/queries/getLinkedAccounts";


export interface SoboState {
    drawerOpen: boolean,
    showExitDialog: boolean,
    searchTerm: string,
    page: number,
    keyword: string | null,
    selectedSoboAccount: LinkedAccount | null
}

export interface SoboAction {
    type: SoboActionEnum,
    payload?: any
}

export enum SoboActionEnum {
    setPage = 'SET_PAGE',
    changeKeyword = 'CHANGE_KEYWORD',
    setDrawerOpen = 'SET_DRAWER_OPEN',
    exitSobo = 'EXIT_SOBO',
    exitSoboConfirm = 'EXIT_SOBO_CONFIRM',
    exitSoboCancel = 'EXIT_SOBO_CANCEL',
    setSelectedAccount = 'SET_SELECTED_ACCOUNT'
}