import React from 'react';

// Components
import HeaderCheckout from 'components/checkout/HeaderCheckout';
import Copyright from 'components/Footer/Copyright';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';

interface Props {}

const LayoutCheckout: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HeaderCheckout shoppingLink="/" pageTitle={'Checkout'} />
      <div className={classes.body}>{props.children}</div>
      <Copyright />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  body: {
    flexGrow: 1,
    minHeight: '80vh',
  },
}));

export default LayoutCheckout;
