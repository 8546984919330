import { gql, useQuery } from '@apollo/client';
import { categories } from './__generated__/categories'
import _get from 'lodash/get'

const CATEGORY_FRAGMENT = gql`
  fragment categoryFields on Category {
    id
    parentId
    sort
    tier
    name
    isLink
    displayName
    hasChildren
    seoCrumb
    img {
      id
      alt
      cdnURL
      __typename
    }
  }
`;

export const GET_CATEGORIES = gql`
  query categories {
    siteCatalog(tree: false) {
      nodes {
        ...categoryFields
      }
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const useGetCatalog = () => {
  const { data, loading, error } = useQuery<categories>(GET_CATEGORIES);

  let categories = _get(data, 'siteCatalog.nodes', []);
  return { data: categories, loading, error };
};
