import { gql } from '@apollo/client';
import { cartLinesFragment } from '../fragments/cartLines';
import { orderAddressFragment } from '../fragments/orderAddressFragment';

export const GET_CART = gql`
  query cart {
    cart {
      id
      itemCount
      subtotal
      total
      messages {
        type
        text
      }
      taxTotal
      freightTotal
      orderShippingAddressId
      orderBillingAddressId
      email
      orderShippingAddress @client {
        ...OrderAddressFragment
      }
      orderBillingAddress @client {
        ...OrderAddressFragment
      }
      addresses {
        ...OrderAddressFragment
      }
      lines {
        ...CartLinesFragment
      }
    }
  }
  ${cartLinesFragment}
  ${orderAddressFragment}
`;

export interface Cart {
  itemCount: number;
  subtotal: number;
  taxTotal: number;
  freightTotal: number;
  total: number;
  lines: Array<{
    listPrice: number;
    name: string;
    amount: number;
    quantity: number;
  }>;
}
