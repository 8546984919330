import React from 'react';
import TextField from '@material-ui/core/TextField';
import { useField, ErrorMessage } from 'formik';

interface Props {
  name: string;
  label: string;
  [key: string]: any;
}

export const Input = ({ label, ...props }: Props) => {
  const [field, meta] = useField(props);
  return (
    <TextField
      label={label}
      {...field}
      {...props}
      onBlur={(e) => {
        if (props.onBlur) {
          props.onBlur(e);
        }
        if (field.onBlur) {
          field.onBlur(e);
        }
      }}
      inputProps={{ readOnly: props.readOnly }}
      error={!!meta.touched && !!meta.error}
      helperText={<ErrorMessage name={field.name} />}
    />
  );
};
