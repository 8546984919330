import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import { addDefaultImage } from 'utils/product-helpers';

import { AddToCartMessage, AttentionMessageVariant } from 'interfaces/messages';

/**
 * The base message for Add to Cart messages
 * @prop {AddToCartMessage} message The message data to show
 * @prop {Function} handleContinueShopping function to be called when "Continue Shopping" is pressed
 */
interface Props {
  message?: AddToCartMessage;
  handleContinueShopping: Function;
}

const AlertMessage: React.FC<Props> = (props) => {
  const classes = useStyles();

  const formatCurrency = (price: any) => (isNaN(price) ? '' : `$${parseFloat(price).toFixed(2)}` || 0);

  const handleContinueShopping = () => {
    props.handleContinueShopping();
  };
  if (!props.message) {
    return <div />;
  }
  return (
    <div className={classes.root} tabIndex={-1}>
      <div className={classes.left}>
        <Link
          component={RouterLink}
          to={`/product-detail/${props.message.seoName}`}
          className={classes.imgLink}
        >
          <div className={classes.thumb}>
            <img
              src={
                (props.message?.img?.length && props.message?.img[0]?.cdnURL)
                || ''
              }
              alt={props.message.name}
              onError={(e: any) => addDefaultImage(e)}
              className={classes.image}
            />
          </div>
        </Link>
        <div className={classes.info}>
          <h4 className={classes.title}>Added to Cart</h4>
          <Link
            component={RouterLink}
            to={`/product-detail/${props.message.seoName}`}
          >
            {props.message.name}
          </Link>
          <div className={classes.price}>
            {props.message.quantity}
            {' '}
            x
            {' '}
            <span>{formatCurrency(props.message.price)}</span>
            {/* {this.props.notification.size ? <span>Size: {this.props.notification.size}</span> : null}
                        {this.props.notification.color ? <span>Color: {this.props.notification.color}</span> : null} */}
          </div>
          {props.message.attributes.map((variant: AttentionMessageVariant) => (
            <div className={classes.variants}>
              {variant.name}
              :
              {variant.value}
            </div>
          ))}
        </div>
      </div>
      <div className={classes.right}>
        <Button
          className={`${classes.button} ${classes.buttonMarginRight}`}
          variant="contained"
          color="primary"
          onClick={handleContinueShopping}
        >
          Continue Shopping
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="secondary"
          href="/cart"
        >
          Go To Cart
        </Button>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: '12.5%',
    paddingRight: '12.5%',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '45%',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '30%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  info: {},
  thumb: {
    marginRight: theme.spacing(4),
  },
  image: {
    maxWidth: '100%',
    maxHeight: '150px',
  },
  button: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  buttonMarginRight: {
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
    fontSize: '20px',
  },
  name: {
    fontSize: '16px',
  },
  price: {
    fontSize: '18px',
    marginTop: theme.spacing(0.5),
  },
  imgLink: {
    flexBasis: 'auto',
    maxwidth: '40%',
  },
  variants: {},
}));

export default AlertMessage;
