import { gql } from '@apollo/client';

export const GET_BREADCRUMBS_FOR_PRODUCT = gql`
  query GET_BREADCRUMBS_FOR_PRODUCT($id: ID!) {
    breadcrumbByProductId(id: $id) {
      id
      Name
      Breadcrumb {
        CategoryId
        Name
      }
    }
  }
`;
