import noop from 'lodash/noop';
import _ from 'lodash';
import { AUTH_TOKEN_NAME, REFRESH_TOKEN_NAME } from 'helpers/constants';
import { decodeToken, isTokenValid } from 'services';
import { DecodedToken } from '../interfaces';
import { login_login } from 'operations/mutations/__generated__/login';
import { PureAbility, AbilityBuilder } from '@casl/ability'
import { createContext } from 'react';
import { createContextualCan } from '@casl/react';


import { ApolloLink, FetchResult, Observable, NextLink, Operation } from '@apollo/client';

const tokenStorage: Storage = localStorage;
interface clearTokenOption {
  redirectToRoot: boolean;
}

const setAuthInfo = (loginData: login_login) => {
  tokenStorage.setItem(AUTH_TOKEN_NAME, loginData.accessToken.token);
};
const getAuthToken = () => {
  const token = tokenStorage.getItem(AUTH_TOKEN_NAME);
  return token;
};

export const getUserFromToken = (token: string | null): DecodedToken | null => {
  if (!token) {
    return null;
  }
  const user = decodeToken(token);
  return user;
};
const getRefreshToken = () => {
  const refreshToken = tokenStorage.getItem(REFRESH_TOKEN_NAME);
  return refreshToken;
};
const clearToken = (options: clearTokenOption = { redirectToRoot: true }) => {
  tokenStorage.removeItem(AUTH_TOKEN_NAME);
  if (options.redirectToRoot && window.location.pathname !== '/') {
    if (window !== undefined) {
      window.location.href = '/';
    }
  }
};

const tryRefreshToken = (fn: Function) => {
  const token = getAuthToken();
  const refreshToken = getRefreshToken();

  if (token && refreshToken) {
    fn({
      variables: {
        accessToken: token,
      },
    });
  } else {
    console.log('token issue in try refresh token');
  }
};



// Apollo bindings for refresh token logic
export interface SubscriberInterface {
  next?: (result: FetchResult) => void;
  error?: (error: Error) => void;
  complete?: () => void;
}

interface QueuedRequest {
  operation: Operation;
  forward?: NextLink;
  subscriber?: SubscriberInterface;
  observable?: Observable<FetchResult>;
  next?: (result: FetchResult) => void;
  error?: (error: Error) => void;
  complete?: () => void;
}

class OperationQueuing {
  public queuedRequests: QueuedRequest[] = [];

  /**
   *
   */
  constructor() {
    this.queuedRequests = [];
  }

  public enqueueRequest(request: QueuedRequest): Observable<FetchResult> {
    const requestCopy = { ...request };

    requestCopy.observable =
      requestCopy.observable ||
      new Observable<FetchResult>((observer) => {
        this.queuedRequests.push(requestCopy);

        if (typeof requestCopy.subscriber === 'undefined') {
          requestCopy.subscriber = {};
        }

        requestCopy.subscriber.next =
          requestCopy.next || observer.next.bind(observer);
        requestCopy.subscriber.error =
          requestCopy.error || observer.error.bind(observer);
        requestCopy.subscriber.complete =
          requestCopy.complete || observer.complete.bind(observer);
      });

    return requestCopy.observable;
  }

  public consumeQueue(): void {
    this.queuedRequests.forEach((request) => {
      // @ts-ignore
      request.forward(request.operation).subscribe(request.subscriber);
    });

    this.queuedRequests = [];
  }
}

class JwtRefreshLink extends ApolloLink {
  private fetching: boolean;

  private queue: OperationQueuing;

  private _onRefreshError: (error: Error) => void;

  constructor(onRefreshError: (error: Error) => void) {
    super();
    this.fetching = false;
    this.queue = new OperationQueuing();
    this._onRefreshError = onRefreshError || noop;
  }

  isTokenValidOrUndefined() {
    const token = getAuthToken();

    if (!token) {
      return true;
    }
    const isValid = isTokenValid(token);
    if (isValid) {
      return true;
    }

    return false;
  }

  request(
    operation: Operation,
    forward: NextLink
  ): Observable<FetchResult> | null {
    if (typeof forward !== 'function') {
      throw new Error(
        '[JWT Refresh Link]: JWT Refresh Link is a non terminating link and should not be the last in the composed chain'
      );
    }

    // If token does not exist, this could mean that this is not authenticated user,
    // Or the token is not expired - work as normal

    if (this.isTokenValidOrUndefined()) {
      return forward(operation);
    }
    if (!this.fetching) {
      this.fetching = true;
      const url = new URL(`${process.env.REACT_APP_API_URL}/graphql`);
      const body = {
        operationName: 'refreshToken',
        variables: {
          accessToken: getAuthToken(),
        },
        query:
          'mutation refreshToken($accessToken: String!) {\n refreshToken(accessToken: $accessToken) {aspNetUserId\n refreshToken\naccessToken {token\n expiresIn\n}\n}\n}',
      };
      fetch(url.toString(), {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'client-name': 'storefront',
          'client-version': '1.0.0',
        },
        body: JSON.stringify(body),
        credentials: 'include',
      })
        .then((res) => res.json())
        .then((json) => {
          // Because we can't use Apollo Client to refresh token
          // We need to handle errors manually in order
          // to do the proper cleanup when token
          // can't be updated.
          const errors = _.get(json, 'errors', undefined);
          if (errors) {
            errors.map(({ message, extensions }: any) => {
              switch (extensions && extensions.code) {
                case 'UNAUTHENTICATED': {
                  throw new Error(message);
                }
              }
            })
          } else {
            setAuthInfo(json.data.refreshToken);
          }

        })
        .catch((error) => {
          // If we encounter error with token refresh
          // We need to log user out. There is no way token can be restored.
          // cache.reset();
          this._onRefreshError(error);
          clearToken();
        })
        .finally(() => {
          this.fetching = false;
          this.queue.consumeQueue();
        });
    }

    return this.queue.enqueueRequest({
      operation,
      forward,
    });
  }
}

export { JwtRefreshLink, getAuthToken, clearToken, tryRefreshToken, setAuthInfo };
