import { gql } from '@apollo/client';

export const FETCH_APP_DATA = gql`
  query FETCH_APP_DATA {
    settings {
      resultsPerPage
      currency
      siteName
      logoPath
      themeName
      taxesOnShipping
      products {
        resultsPerPage
      }
      customers {
        resultsPerPage
      }
      selectedTheme {
        themeId
        colorPrimary
        colorSecondary
        textPrimary
        textSecondary
        error
        warning
        info
        success
        background
        panelBackground
        h1
        h2
        h3
        h4
        h5
        h6
        body1
        body2
        h1Font
        h2Font
        h3Font
        h4Font
        h5Font
        h6Font
        body1Font
        body2Font
        xsBreakpoint
        smBreakpoint
        mdBreakpoint
        lgBreakpoint
        xlBreakpoint
        defaultTheme
        themeName
        fontFamily
      }
    },
    me {
      claims {
        id
        action
        actionId
        subject
        subjectId
        field
        fieldId
        isCan
        isPassThrough
      }
    }
    
  }
`;
