import React from 'react';
import TextField from '@material-ui/core/TextField';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  Select as MuiSelect,
} from '@material-ui/core';
import { useField, ErrorMessage } from 'formik';

interface Props {
  name: string;
  label: string;
  [key: string]: any;
}

export const Select = ({ label, ...props }: Props) => {
  const [field, meta] = useField(props);
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        label={label}
        {...field}
        {...props}
        fullWidth
        onBlur={(e) => {
            if (props.onBlur) {
                props.onBlur(e);
            }
            if (field.onBlur) {
                field.onBlur(e);
            }
        }}
        error={!!meta.touched && !!meta.error}
        // helperText={<ErrorMessage name={field.name} />}
      >
        {props.children}
      </MuiSelect>
    </FormControl>
  );
};
