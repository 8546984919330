import React from 'react';
// import {Link} from 'react-router-dom';
// import isFunction from 'lodash/isFunction';
import CardContent from '@material-ui/core/CardContent';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Divider from '@material-ui/core/Divider';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {formatPhoneNumber} from "../utils/format-phone";
// import {ContactInfo} from "../../../interfaces/ContactInfo";

export interface AccountCardProps {
  contact: any; //This can be made generic at a later date when we deal with more contact related data
  shouldShowPrimaryAction?: boolean;
  shouldShowSecondaryAction?: boolean;
  cardActionText?: string;
  handleClick: (data?: any) => void;
  handleActionClick: () => void;
  cardSecondaryActionText?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  accountIcon: {
    marginRight: '1rem'
  },
  divider: {
    marginBottom: theme.spacing(2)
  },
  exitSoboBtn: {
    marginTop: theme.spacing(2)
  }
}));
export const AccountCard: React.FC<AccountCardProps> = ({
                                                          contact,
                                                          shouldShowPrimaryAction,
                                                            shouldShowSecondaryAction,
                                                          cardActionText,
                                                          handleClick,
                                                          handleActionClick,
                                                          cardSecondaryActionText
                                                        }) => {
  const classes = useStyles();

  const handleProfileClick = () => {
    // if (isFunction(handleActionClick)) {
    //
    // }
    handleActionClick();
  };
  return (
      <>
        <Card>
          <CardContent>
            <Toolbar variant="dense" disableGutters>
              <Typography variant="h6" className={classes.cardHeader}>
                <AccountCircleIcon
                    className={classes.accountIcon}
                />
                {contact.fullName}
              </Typography>
            </Toolbar>
            <Divider className={classes.divider}/>
            {contact.code ? (
                <Typography variant="subtitle2" component={'p'}>
                  Account Code: {contact.code}
                </Typography>
            ) : null}
            {contact.email ? (
                <Typography variant="subtitle2" component={'p'}>
                  E-mail: {contact.email}
                </Typography>
            ) : null}
            {contact.phoneNumber ? (
                <Typography variant="subtitle2" component={'p'}>
                  Phone: {formatPhoneNumber(contact.phoneNumber)}
                </Typography>
            ) : null}
          </CardContent>
          {shouldShowSecondaryAction && (
              <CardActions className={classes.cardActions}>
                <Button
                    onClick={handleProfileClick}
                    // component={Link}
                    // to="/account"
                >
                  {cardSecondaryActionText}
                </Button>
              </CardActions>
          )}
        </Card>
          {shouldShowPrimaryAction && <Button
              variant={'contained'}
              color={'primary'}
              onClick={handleClick}
              className={classes.exitSoboBtn}
          >
          {cardActionText}
        </Button> }
      </>
  );
};
