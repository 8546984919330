import React, {useState} from 'react';
import {useHistory, Link as RouterLink} from 'react-router-dom';
import {useQuery, useMutation} from '@apollo/client';
import Currency from 'components/Currency';

import {makeStyles, Theme} from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {Typography, IconButton, Badge, Box} from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';

// Icons
import CloseIcon from '@material-ui/icons/Close';

import {GET_CART, Cart} from 'operations/queries/getCart';
import {DELETE_CART_LINE} from 'operations/mutations/deleteCartLine';

import {useTranslation} from 'react-i18next';

import ConfirmationMessage from 'components/GlobalMessages/components/Messages/ConfirmationMessage';
import NoResults from 'components/NoResults';
import ProductMiniCartItem from './ProductMiniCartItem';
import SaveNewCartDialog from 'pages/my-account/sub-pages/saved-carts/components/saveNewCartDialog';

interface Props {
    className?: string;
}

const MiniCart: React.FC<Props> = (props) => {
    const {data, loading, error} = useQuery<{ cart: Cart }>(GET_CART);
    const classes = useStyles(props);
    const {t} = useTranslation();
    const [openSaveCartDialog, setSavedCartDialog] = useState(false);
    const [state, setState] = useState({
        miniCartOpen: false,
    });

    const [removeCartLine] = useMutation(DELETE_CART_LINE);

    const [productToDelete, setProductToDelete] = useState<any>(null);

    const handleCloseSaveCart = (bool:boolean) => {
        setSavedCartDialog(bool);
        setState({
            miniCartOpen: false,
        });
    }


    const handleSetItemToDelete = async (cartLine: {
        productId: string;
        listPrice: number;
        name: string;
        amount: number;
        quantity: number;
    }) => {
        setProductToDelete(cartLine);
    };

    const handleDeleteItem = async (productId: string) => {
        setProductToDelete(null);
        await removeCartLine({
            variables: {
                input: {
                    productId: productId
                }
            },
        });
    };

    const toggleDrawer = () => (event: any) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({miniCartOpen: !state.miniCartOpen});
    };

    const getTotalQuantity = (cart: any) => (cart && cart.itemCount) || 0;

    if (loading || error) {
        return null;
    }
   
    // const cart = (data && data.cart) || { lines: [] };
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <Typography>
                <IconButton
                    onClick={toggleDrawer()}
                    color="inherit"
                >
                    <Badge badgeContent={getTotalQuantity(data?.cart)}>
                        <ShoppingCartIcon/>
                    </Badge>
                </IconButton>
            </Typography>

            <div className={`${classes.root} ${props.className || ''}`}>
                <SwipeableDrawer
                    anchor="right"
                    open={state.miniCartOpen}
                    onOpen={toggleDrawer()}
                    onClose={toggleDrawer()}
                    className={classes.menuDrawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <Toolbar className={classes.toolbar} disableGutters>
                        <IconButton onClick={toggleDrawer()} color="inherit">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h5">Cart</Typography>
                        <div className={classes.stretch}/>
                        <Typography variant="h6">
                            Total:{' '}
                            <strong>
                                <Currency value={data?.cart?.subtotal}/>
                            </strong>
                        </Typography>
                        &nbsp;
                    </Toolbar>
                    {data?.cart?.lines?.length ?
                        (
                            <>
                                <div className={`${classes.buttonWrapper} ${classes.desktopButton}`}>
                                    <Link
                                        component={RouterLink}
                                        to="/cart"
                                        onClick={() => {
                                            setState({miniCartOpen: false});
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            fullWidth
                                            disableElevation
                                        >
                                            View Cart
                                        </Button>
                                    </Link>
                                </div>
                                <Divider/>
                            </>) : null}
                    <div className={classes.content}>
                        {data?.cart?.lines?.map((cartLine: any, i: number) => (
                            <ProductMiniCartItem
                                key={cartLine.productId}
                                cartLine={cartLine}
                                onDelete={handleSetItemToDelete}
                                setState={setState}
                            />
                        ))}
                        {!data || (data && !(data?.cart?.lines?.length > 0)) ? (
                            <NoResults icon="cart">Your cart is empty</NoResults>
                        ) : null}
                    </div>
                    {data?.cart?.lines?.length ? (
                        <>
                            <Divider/>
                            <div className={classes.buttonWrapper}>
                                <Link
                                    component={RouterLink}
                                    to="/checkout"
                                    onClick={() => {
                                        setState({miniCartOpen: false});
                                    }}
                                    underline="none"
                                >
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        fullWidth
                                        disableElevation
                                    >
                                        {t('cartSummary.checkout.btn')}
                                    </Button>
                                </Link>
                            </div>
                            <Divider/>
                            <div className={classes.buttonWrapper}>
                                <Link
                                    component={RouterLink}
                                    to="/cart"
                                    onClick={() => {
                                        setState({miniCartOpen: false});
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        fullWidth
                                        disableElevation
                                    >
                                        View Cart
                                    </Button>
                                </Link>

                                <Button
                                    style={{marginTop:'20px'}}
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    onClick = {() => setSavedCartDialog(true)}
                                    disableElevation
                                >   
                                    Save Current Cart
                                </Button>
                            </div>
                        </>
                    ) : null}
                    {productToDelete ? (
                        <ConfirmationMessage
                            severity="error"
                            handleYes={() => handleDeleteItem(productToDelete.productId)}
                            handleNo={() => setProductToDelete(null)}
                        >
                            Are you sure you want to delete this item?
                        </ConfirmationMessage>
                    ) : null}
                </SwipeableDrawer>
            </div>

            <SaveNewCartDialog
                open={openSaveCartDialog}
                handleClose={handleCloseSaveCart}
                hasItems={data?.cart?.lines ? data?.cart?.lines.length > 0 : false}

              />
        </Box>
    );
};

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    content: {
        flexGrow: 1,
        minWidth: 300,
        overflow: 'auto',
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        paddingRight: theme.spacing(1)
    },
    buttonWrapper: {
        padding: theme.spacing(2),
    },
    desktopButton: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    footer: {},
    items: {},
    stretch: {
        flexGrow: 1,
    },

    menuDrawer: {
        justifyContent: 'center',
        flexShrink: 0,
    },
    drawerPaper: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 440,
        },
    },
    button: {
        width: '70%',
        margin: '0 auto',
    },
}));

export default MiniCart;
