import { ReactiveVar } from '@apollo/client';

export default function addAttentionMessage(messagesVar: ReactiveVar<any>) {
  const createNewMessageId = (allMessages: any) => (
    allMessages.reduce(
      (maxId: number, message: any) => Math.max(message.id, maxId),
      -1,
    ) + 1
  );

  const createNewMessage = (
    text: string,
    variant: string,
    severity: string,
    allMessages: any,
  ) => ({
    text,
    variant,
    severity,
    id: createNewMessageId(allMessages),
  });

  return (text: string, variant: string, severity: string) => {
    const allMessages = messagesVar();
    messagesVar(
      allMessages.concat([
        createNewMessage(text, variant, severity, allMessages),
      ]),
    );
  };
}
