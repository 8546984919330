import React, {useContext, useRef, useState} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import UserSession from 'components/userSession';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  AppBar, Box, Container, Toolbar,
} from '@material-ui/core';

import Navigation, {
  NavigationProvider,
  MobileMenuToggle,
} from 'components/navigation';
import GlobalMessages from 'components/GlobalMessages';
import { MenuNode } from '../interfaces';

import Search from './Search';
import MiniCart from './MiniCart';

import Logo from './Logo';
import SoboDrawer from "./Sobo/SoboDrawer";
import {useQuery} from "@apollo/client";
import { useAbilityState } from "../utils/AbilityContext";

interface Props {
  menuJson: MenuNode[] | null;
}

const Header: React.FC<Props> = ({ menuJson }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [searchOpen, setSearchOpen] = useState(location.pathname === '/search');
  const ability = useAbilityState();
  const appBar = useRef<any>();
  /*
   * When location change close the search box if it is open
   */
  history.listen((_) => {
    if (searchOpen) {
      setSearchOpen(false);
    }
  });

  const toggleSearchMenu = (isOpen: boolean) => setSearchOpen(isOpen);

  return (<>
    {/* <Box style={{ position: 'relative' ,zIndex:1000}}> */}

      {/* FIXME: introduction of position sticky in appbar casues issues with popover elements in material UI.
                Every popup will remove the scroll bar, and the width of the header will not fill the full width of the page. width:101% will work
                should probably change this in the future
      */}
      <AppBar position="sticky" color="inherit" ref={appBar} style={{width:'101%'}}>
        <Container maxWidth="xl">
          <Toolbar className={classes.root}>
          <Box component="div" display="flex" flexDirection="column" width={1}>
            <NavigationProvider>
              <Box component="div" className={classes.header} display="flex">
              <Link to="/" className={classes.logoWrapper}>
                <Logo />
              </Link>
              <div className={classes.menuIcons}>
                {!searchOpen && <MobileMenuToggle />}
                <Search
                  isOpen={searchOpen}
                  toggleSearchMenu={toggleSearchMenu}
                  enableTypeahead={true}
                />
                {!searchOpen && (
                  <>
                    <UserSession />
                    <MiniCart />
                    {/* TODO: Talk to Arthur about ability {ability.can('read' , 'Cart') && <MiniCart />} */}

                    {ability.can('read' , 'Sobo') && <SoboDrawer />}

                  </>
                )}
              </div>
              </Box>
              <Navigation />
            </NavigationProvider>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <GlobalMessages />
    {/* </Box> */}
  </>);
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexWrap: 'wrap',
    paddingTop: theme.spacing(0.8)
  },
  header:{
    display:'flex',
    flexDirection:'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection:'column',
    },
  },
  logoWrapper: {
    '@media screen and (max-width: 775px)': {
      flexGrow: 1,
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
    },
  },
  menuIcons: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
    },
  },
}));

export default Header;
