import React, { MouseEvent, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';
import { Box, Button, ListItem, MenuItem, Typography, useMediaQuery } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import { MenuNode } from 'interfaces';
import { useTheme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import { useTranslation } from 'react-i18next';



interface Props {
  menuJson: MenuNode[];
  parentNodes: MenuNode[];
  childrenNodes: MenuNode[];
}

const MegaEveMenu: React.FC<Props> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selected, setSelected] = useState<number>(0);
  const [highlighted, setHighlighted] = useState<number>(-1);
  const { t } = useTranslation();
  const theme = useTheme();
  const isMediumiewport = useMediaQuery(theme.breakpoints.down('md'));

  const handleClick = (event: MouseEvent<HTMLElement>,index:number) => {
    setAnchorEl(event.currentTarget);
    setSelected(index);
    setHighlighted(index);
  };

  const handleClose = (node: MenuNode) => {
    if (node && node.id) {
      let rootId = props.childrenNodes.find((n: any) => n.id === node.parentId)?.parentId;
      let rootCrumb = props.parentNodes.find((n: any) => n.id === rootId)?.seoCrumb;

      if(node.seoCrumb.toString().includes("shop-by-series")) {
        history.push(`/product-list/${rootCrumb}?Series=${node.displayName}`);
      }
      else if (node.seoCrumb.toString().includes("shop-by-family")) {
        history.push(`/product-list/${rootCrumb}?Family=${node.displayName}`);
      }
      else {
        history.push(`/product-list/${node.seoCrumb}`);
      }
    }
    setHighlighted(-1);
    setAnchorEl(null);
  };

  
  const parentNodes = (props.parentNodes) ? props.parentNodes?.sort((a:any, b:any) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0)) : [];
  const propertyId =  "id";
  const propertyParent = "parentId"

  const renderMenu = (parentNode: any,nested:boolean,margin:number) => {

    let subMenu: JSX.Element;
		const id = parentNode[propertyId];
    const children = props.childrenNodes?.filter((obj:any) => obj.parentId === id).sort((a:any, b:any) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0))

    const menu = children?.map((node: any) => {
      let currentNodeId = node[propertyParent];
      /*
      * If id is equal to the current nodes parentId then render another subMenu
      * Otherwise skip.
      */
      if(currentNodeId === id){
        subMenu = renderMenu(node,true,margin + 20);
      }

      return (
        <div key={node.id} className={classes.category}>
          {node.isLink ?
            <MenuItem
              component={'a'}
              key={node.id}
              style={{marginLeft:`${margin}px`}}
              classes={{
                gutters: classes.menuItemGutters,
                root: classes.menuItemRoot,
              }}
              onClick={(e: any) => handleClose(node)}
            >
              {node.displayName}
            </MenuItem> :
            <ListItem
              component={'li'}
              key={node.id}
              style={{
                marginLeft:`${margin}px`,
                fontSize:'1rem'
              }}
              classes={{
                gutters: classes.menuItemGutters,
                root: classes.menuItemRootNoLink,
              }}
            >
              {node.displayName}
            </ListItem>
          }
          {currentNodeId === id ? <div className={classes.nestedMenu}> {subMenu}</div>:null}
        </div>
      );
    });
    return (
      <Box className={classes.menuContainer} display="flex" justifyContent={!nested ? 'space-around':'flex-start'} > 

      <div className={classes.megamenu} style={!nested ? {margin:'auto'}: {}}>
        {!nested ? (
            <div>
              <Typography className={classes.menuTitle} variant="h5" component="h3" gutterBottom={true}>
                {`${parentNode?.name}`.toLocaleUpperCase()}
              </Typography>
            </div>
          ) : null}
        <div className={classes.full}>
          <div className={classes.menuItems}>{menu}</div>
          {!nested 
            ? parentNode && (
                <div className={classes.category}>
                  <MenuItem
                    key={parentNode.id}
                    classes={{
                      gutters: classes.menuItemGutters,
                      root: classes.menuItemRoot,
                    }}
                    onClick={(e) => handleClose(parentNode)}
                  >
                    Shop all {parentNode.displayName}
                  </MenuItem>
                </div>
              )
            : null}
        </div>
    
      </div>
      {!nested && parentNode.img[0]  && !isMediumiewport? (
            <div className={classes.image}>
                <img
                  src={parentNode.img[0].cdnURL || ''}
                  alt={parentNode?.name || ''}
                />
            </div>
          ) : null}
      </Box>
    );
  };

  
  return (
    <div className={classes.menu}>
      {props.menuJson && props.menuJson.length ? (
        <div className={classes.root}>
          { parentNodes ? parentNodes.map((node: MenuNode, index:any) => (
              <MenuItem
                  key={node.id}
                  aria-controls={`menu-${node.id}`}
                  onClick={(e: any) => handleClick(e, index)}
                  button
                  selected={highlighted === index}
              >
                  {node.displayName}
              </MenuItem>
          )): 
          (
            <>
              <Skeleton
                variant="rect"
                width={120}
                height={30}
                className={classes.skeleton}
              />
            </>
          )}
            <Menu
                PopoverClasses={{ paper: classes.popoverPaper }}
                classes={{ list: classes.menulist }}
                style={{zIndex:1}}
                open={
                  anchorEl !== null 
                }
                className={classes.menulist}
                anchorEl={anchorEl}
                onClose={handleClose}
                keepMounted
                getContentAnchorEl={null}
              >
                {renderMenu(parentNodes[selected],false,0)}
          </Menu>
          {/* Menu item to go to available inventory page */}
          
          <MenuItem
            aria-controls={`menu-available-inventory`}
            onClick={(e: any) => history.push(`/available-inventory`)}
            button
          >
            {t('availableInventory.text')}
          </MenuItem>
        </div>
      ) : (
        <>
          <Skeleton
            variant="rect"
            width={120}
            height={30}
            className={classes.skeleton}
          />
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center'
  },
  menuTitle: {
    marginTop:theme.spacing(1),
    marginBottom:theme.spacing(2)
  },
  menuContainer:{
    width:'90%',
    [theme.breakpoints.down('md')]: {
      display:'block'
    },
  },
  popoverPaper: {
    zIndex: 1,
    left: '0 !important',
    top: '110px !important',
    width: '100%',
    paddingRight:'0 !important',
    // maxHeight: '650px',
    maxWidth: '100%',
    overflow:'hidden'
  },
  category: {
    display: 'flex',
    flexDirection:'column',
    margin:'0 10px 0 10px',
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '200px'
    },
  },
  menuItems: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateRows: 'repeat(5, auto)',
    width: 'auto',
    padding:'5px',
    [theme.breakpoints.down('md')]: {
      gridTemplateRows: 'repeat(7, auto)'
    },
  },
  menulist: {
    display: 'flex',
    justifyContent: 'center',
    padding: '30px 15px 30px 15px',
    height: 'auto',
  },
  menuItemRoot: {
    minWidth: '50px',

    transition: 'all 0.1s',
    color: 'rgba(0, 0, 0, 0.7)',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'black',
    },
  },
  menuItemRootNoLink: {
    minWidth: '50px',
    color: 'rgba(0, 0, 0, 0.7)',
  },
  nestedMenu: {
    display:'flex',
    flexDirection:'row',
    width:'auto'
  },
  menuItemGutters: {
    padding:'0'
  },
  full: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    padding: 0,
  },  
  image: {
    paddingTop: 200,
    width: '100%',
    minHeight:'460px',
    height:'100%',
    minWidth:'500px',
    maxWidth:'1000px',
    position: 'relative',
    marginLeft: 'auto',
    '& > img': {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  },
  menu: {
    flexGrow: 1,
    display: 'flex',
    flexFlow:'wrap',
    justifyContent:'center',
    alignItems:'center',

    '@media screen and (max-width: 768px)': {
      order: 1,
      width: '100%',
      justifyContent: 'center',
    },
  },
  megamenu: {
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'column',
    width:'auto',
  
  },
  skeleton: {
    marginRight: 10,
  },
}));

export default MegaEveMenu;
