/* eslint-disable max-classes-per-file */
import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import { getAuthToken, getUserFromToken, JwtRefreshLink } from 'utils/auth';
import WebFont from 'webfontloader';
import {
  ApolloClient,
  ApolloProvider,
  gql,
  ApolloLink,
  HttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { resolvers, typeDefs } from 'resolvers';

import './index.css';
import { FETCH_APP_DATA } from 'operations/queries/getAppData';
import { cache } from 'Cache';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { GET_PREVIEW_THEME } from './operations/queries/theme';
import { AbilityProvider } from 'utils/AbilityContext';


const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      // eslint-disable-next-line
      graphQLErrors.map(({ message, locations, path, extensions }) => {
        switch (extensions && extensions.code) {
          case 'UNAUTHENTICATED': {
            // TODO: Try token refresh here
          }
        }
        console.log(
          `[GraphQL error]: ${message}, Location: ${locations}, Path: ${path}`
        );
      });
    }

    if (networkError) {
      console.log(`[Network Error]: ${networkError}`);
    }
  }
);

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  credentials: 'include',
  headers: {
    'client-name': 'storefront',
    'client-version': '1.0.0',
  },
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAuthToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const link = ApolloLink.from([
  new JwtRefreshLink(() => {
    cache.reset();
  }),
  authLink,
  errorLink,
  httpLink,
]);

const client = new ApolloClient({
  cache,
  link,
  typeDefs,
  resolvers,
});

const loadFonts = (fontFamily: string[]) => {
  WebFont.load({
    google: {
      families: fontFamily,
    },
  });
};

async function init() {
  client.writeQuery({
    query: gql`
      query INITIAL_DATA {
        isLoggedIn @client
        shippingbyCarrier {
          name
          code
          baseAmount
        }
        user @client {
          deletedAt
          email
          emailConfirmed
          exp
          firstName
          iat
          id
          lastName
        }

        settings {
          themeName
          logoPath
        }
      }
    `,
    data: {
      isLoggedIn: !!getAuthToken(),
      user: getUserFromToken(getAuthToken()),
      shippingbyCarrier: [],
      settings: {
        themeName: '',
        logoPath: '',
      },
      cart: {
        lines: [],
      },
    },
  });
  const paramParser = new URLSearchParams(window.location.search);
  const previewThemeId = paramParser.get('previewTheme');
  try {
    const result = await client.query({
      query: FETCH_APP_DATA,
    });
    let customTheme = result.data.settings?.selectedTheme || {};
    if (previewThemeId) {
      const themeData = await client.query({
        query: GET_PREVIEW_THEME,
        variables: { id: previewThemeId },
      });
      customTheme = themeData?.data.theme || {};
    }

    if (customTheme.fontFamily) {
      const fonts: string[] = [];
      Object.keys(customTheme).forEach((key) => {
        if (key.toLowerCase().includes('font')) {
          fonts.push(customTheme[key]);
        }
      });
      loadFonts(fonts.filter((font) => font));
    }

    client.writeQuery({
      query: gql`
        query SETTINGS {
          settings {
            themeName
          }
        }
      `,
      data: {
        settings: result.data.settings,
      },
    });

    ReactDOM.render(
      <ApolloProvider client={client}>
        <AbilityProvider>
          <App
            isLoggedIn={!!getAuthToken()}
            settings={result.data.settings}
            previewTheme={customTheme}
          />
        </AbilityProvider>
      </ApolloProvider>,
      document.getElementById('root')
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
  } catch (error) {
    console.error('error fetching data ', error);
  }
}

init();
