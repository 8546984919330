import { gql } from '@apollo/client';

export const GET_PREVIEW_THEME = gql`
  query getTheme($id: ID!) {
    theme(id: $id) {
      background
      body1
      body1Font
      body2
      body2Font
      error
      h1
      h1Font
      h2
      h2Font
      h3
      h3Font
      h4
      h4Font
      h5
      h5Font
      h6
      h6Font
      info
      lgBreakpoint
      mdBreakpoint
      panelBackground
      smBreakpoint
      xlBreakpoint
      xsBreakpoint
      success
      warning
      fontFamily
      colorPrimary
      colorSecondary
      textPrimary
      textSecondary
      themeName
    }
  }
`;
