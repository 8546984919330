import React from 'react';
import isFunction from 'lodash/isFunction';
import { useTranslation } from 'react-i18next';
// src
import SaveNewCartForm from './saveNewCartForm';
// #region Material-UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ConfirmationMessage from 'components/GlobalMessages/components/Messages/ConfirmationMessage';

// #endregion Material-UI
interface Props {
  open: boolean;
  handleClose: (event?: any) => void;
  hasItems?:boolean;
}

const SaveNewCartDialog = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const handleClose = () => {
    if (isFunction(props.handleClose)) {
      props.handleClose(false);
    }
  };

  return (
    <Dialog
      PaperProps={{ classes: {root: classes.dialogPaper } }}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {'Save Current Cart'}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <SaveNewCartForm
          handleCancel={handleClose}
          handleSuccess={handleClose}
          hasItems={props.hasItems}
        />
      </DialogContent>

    
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
    dialogPaper: {
        minWidth:'400px'
    }
  }));
  

export default SaveNewCartDialog;
