import React, { useState, useEffect, useRef } from 'react';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';

import AlertMessage from 'components/GlobalMessages/components/Messages/AlertMessage';

/**
 * A message to be used for functionality relating to confirmation messages
 * @prop {Function} severity The severity of the message (determines the message style)
 * @prop {Function} handleYes function to be called when yes is pressed
 * @prop {Function} handleNo function to be called when no is pressed
 */
interface Props {
  severity: 'error' | 'warning' | 'info' | 'success';
  handleYes: Function;
  handleNo: Function;
}

/**
 * A message to be used for functionality relating to confirmation messages
 * @param {Object} allProps All props of this Button
 */
const ConfirmationMessage: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div
        className={`${classes.highAttention} ${classes.animationInBackdrop}`}
      >
        <div className={classes.animationInMessage}>
          <AlertMessage
            variant="confirmation"
            severity={props.severity}
            handleYes={props.handleYes}
            handleNo={props.handleNo}
          >
            {props.children}
          </AlertMessage>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  highAttention: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    background: 'rgba(355,355,355, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mediumAttention: {
    position: 'fixed',
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    right: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  alertMarginBottom: {
    marginBottom: theme.spacing(1),
  },
  animationInMessage: {
    position: 'relative',
    display: 'block',
    transform: 'translateY(15%)',
    animation: '$fadeInUp 100ms linear forwards',
    zIndex: 1,
  },
  '@keyframes fadeInUp': {
    '0%': {
      opacity: 0,
      transform: 'translateY(15%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  animationInBackdrop: {
    animation: '$fadeIn 100ms linear forwards',
    zIndex: 1,
    opacity: 1,
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

export default ConfirmationMessage;
