import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import RouteGuard from './Route';

// TODO: In the next iteration make it more dynamic.
// Redis can be used for serving the page definition
const pages = [
  {
    id: 1,
    name: 'Home',
    path: '/',
    componentPath: 'home',
    routePatth: '',
    isPrivate: true,
    roles: [],
    exact: true,
    layout: 'auth',
  },
  {
    id: 2,
    name: 'Login',
    componentPath: 'login',
    path: '/login',
    isPrivate: false,
    roles: [],
    layout: 'inner',
  },
  // {
  //   id: 3,
  //   name: 'Registration',
  //   componentPath: 'register',
  //   path: '/registration',
  //   isPrivate: true,
  //   roles: [],
  //   layout: '',
  // },
  {
    id: 4,
    name: 'ForgotPassword',
    componentPath: 'forgot-password',
    path: '/forgot-password',
    isPrivate: false,
    requiresUnauthenticated: false,
    roles: [],
    layout: 'inner',
  },
  {
    id: 5,
    name: 'ResetPassword',
    componentPath: 'reset-password',
    path: '/reset-password',
    requiresUnauthenticated: false,
    isPrivate: false,
    roles: [],
    layout: 'inner',
  },
  // {
  //   id: 6,
  //   name: 'ProductList',
  //   componentPath: 'product-list',
  //   path: '/product-list/:categoryId?',
  //   isPrivate: true,
  //   roles: [],
  //   layout: 'auth',
  // },
  {
    id: 6,
    name: 'ProductList',
    componentPath: 'product-list',
    path: '/product-list/:categoryId+',
    isPrivate: false,
    roles: [],
    layout: 'auth',
  },
  {
    id: 7,
    name: 'ProductDetail',
    componentPath: 'product-detail',
    path: '/product-detail/:id',
    isPrivate: true,
    roles: [],
    layout: 'auth',
  },
  {
    id: 8,
    name: 'SearchResults',
    componentPath: 'search-results',
    path: '/search',
    isPrivate: true,
    roles: [],
    layout: 'auth',
  },
  {
    id: 9,
    name: 'Cart',
    componentPath: 'cart',
    path: '/cart',
    isPrivate: true,
    roles: [],
    layout: 'auth',
  },
  {
    id: 10,
    name: 'Checkout',
    componentPath: 'checkout',
    path: '/checkout',
    isPrivate: true,
    roles: [],
    layout: 'checkout',
  },
  {
    id: 11,
    name: 'Confirmation',
    componentPath: 'confirmation',
    path: '/confirmation',
    isPrivate: true,
    roles: [],
    layout: '',
  },
  {
    id: 12,
    name: 'MyAccount',
    componentPath: 'my-account',
    path: '/account',
    isPrivate: true,
    roles: [],
  },
  {
    id: 13,
    name: 'MediaGallery',
    componentPath: 'media-gallery',
    path: '/media-library',
    isPrivate: true,
    roles: [],
    layout: '',
  },
  {
    id: 14,
    name: 'AboutUs',
    componentPath: 'about-us',
    path: '/about-us',
    isPrivate: false,
    roles: [],
    layout: '',
  },
  {
    id: 15,
    name: 'ContactUs',
    componentPath: 'contact-us',
    path: '/contact-us',
    isPrivate: false,
    roles: [],
    layout: '',
  },
  {
    id: 16,
    name: 'PrivacyPolicy',
    componentPath: 'privacy-policy',
    path: '/privacy-policy',
    isPrivate: false,
    roles: [],
    layout: '',
  },
  {
    id: 17,
    name: 'AvailableInventoryList',
    componentPath: 'available-inventory',
    path: '/available-inventory',
    isPrivate: false,
    roles: [],
    layout: '',
  },
  {
    id: 18,
    name: 'SavedCarts',
    componentPath: 'saved-carts',
    path: '/saved-carts',
    isPrivate: true,
    roles: [],
    layout: '',
  },
  {
    id: 19,
    name: 'SavedCartDetail',
    componentPath: 'saved-cart-details',
    path: '/saved-carts/details/:id',
    isPrivate: true,
    roles: [],
    layout: '',
  },
  {
    id: 20,
    name: '',
    componentPath: 'NotFound',
    path: '/404',
    isPrivate: false,
    roles: [],
    layout: '',
  },
];

export default function Routes() {
  return (
    <Switch>
      {pages.map((page) => {
        return (
          <RouteGuard
            key={page.id}
            path={page.path}
            layout={page.layout}
            component={page.componentPath}
            isPrivate={page.isPrivate}
            exact={page.exact}
            requiresUnauthenticated={page.requiresUnauthenticated}
          />
        );
      })}
      <Redirect to="/404" />
    </Switch>
  );
}
