import { gql, InMemoryCache, ReactiveVar, makeVar } from '@apollo/client';
import { SHIPPING_BY_CARRIERS } from 'operations';
import { AddToCartMessage, AttentionMessage } from 'interfaces/messages';
import { GET_CART } from 'operations/queries/getCart';
import { isSerializedItem } from 'utils/product-helpers';

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Product: {
      fields: {
        // We need to block the serialized products from adding to cart more than one item.
        // The field policy seems like a better way to go as the front end updates as we add / remove items
        // from the cart. In the server resolved field we will need to refetch list on every cart operation.
        isInCart: {
          read(args, context) {
            let cache = context.cache;
            let seoName = context.readField('seoName');


            // If item is not serialized product there is no need to disable add to cart button
            if (!isSerializedItem({code: seoName})) {
              return false;
            }

            try {
              let cart: any = cache.readQuery({
                query: GET_CART,
              });
              
              let lines = (cart && cart.cart && cart.cart.lines) || [];
              let ids = lines.map((line: any) => line.productId);
              

              let currentProductId = context.readField('id');
              return ids.includes(currentProductId);
            } catch (error) {
              return false;
            }
            
          }
        }
      }
    },
    Cart: {
      fields: {
        selectedShipping: {
          read(curr, args) {
            try {
              const carriers: any = args.cache.readQuery({
                query: SHIPPING_BY_CARRIERS,
                variables: {
                  id: args.variables?.id,
                },
              });
              const cart: any = args.cache.readQuery({
                query: gql`
                  query CART_IN {
                    cart {
                      id
                      shippingMethodId
                    }
                  }
                `,
              });
              const shippingMethodId = cart?.cart?.shippingMethodId;

              if (
                shippingMethodId &&
                Array.isArray(carriers?.shippingbyCarrier)
              ) {
                const selected = carriers?.shippingbyCarrier.find(
                  (method: any) => method.id === shippingMethodId
                );

                if (selected) {
                  return selected;
                }
                return null;
              }
            } catch (error) {
              console.log('cache not populated yet.');
              return null;
            }

            return null;
          },
        },
      },
    },
    Query: {
      fields: {
        lowAttentionMessages: {
          read() {
            return lowAttentionMessagesVar();
          },
        },
        mediumAttentionMessages: {
          read() {
            return mediumAttentionMessagesVar();
          },
        },
        highAttentionMessages: {
          read() {
            return highAttentionMessagesVar();
          },
        },
        addToCartMessages: {
          read() {
            return addToCartMessagesVar();
          },
        },
      },
    },
  },
});

export const lowAttentionMessagesVar: ReactiveVar<AttentionMessage[]> = makeVar<
  AttentionMessage[]
>([]);

export const mediumAttentionMessagesVar: ReactiveVar<
  AttentionMessage[]
> = makeVar<AttentionMessage[]>([]);

export const highAttentionMessagesVar: ReactiveVar<
  AttentionMessage[]
> = makeVar<AttentionMessage[]>([]);

export const addToCartMessagesVar: ReactiveVar<AddToCartMessage[]> = makeVar<
  AddToCartMessage[]
>([]);
