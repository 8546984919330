import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useQuery, gql } from '@apollo/client';
import { GET_SETTINGS } from 'operations';
import { settings } from 'operations/queries/__generated__/settings';
interface Props {
  logoMaxWidth?: string;
}

const Logo = (props: Props) => {
  const classes = useStyles(props);
  const { data } = useQuery<settings>(GET_SETTINGS);

  return (
    <img
      className={classes.logo}
      src={data?.settings?.logoPath || undefined}
      alt="site logo"
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    height: 'auto',
    maxWidth: (props: Props) =>
      props.logoMaxWidth ? props.logoMaxWidth : '150px',
    marginRight: '1.5rem',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      margin: '0 auto 0 auto',
    },
  },
}));

export default Logo;
