import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles, Theme } from '@material-ui/core/styles';
import {Box, Container, Toolbar, Typography} from '@material-ui/core';

import Logo from 'components/Logo';

interface Props {
  shoppingLink: string;
  pageTitle?: string;
}

const Header: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return (
    <Box style={{ position: 'relative' }}>
      <div className={classes.container}>
        <Container maxWidth="xl">
          <Toolbar className={classes.root}>
            <Link to="/" className={classes.logoWrapper}>
              <Logo />
            </Link>
            {props.pageTitle && <Typography variant={'h4'}>{props.pageTitle}</Typography>}
            <Link to={props.shoppingLink} className={classes.linkWrapper}>
              Back to Shopping
            </Link>
          </Toolbar>
        </Container>
      </div>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexWrap: 'wrap',
    paddingTop: theme.spacing(0.8),
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },
  logoWrapper: {
    '@media screen and (max-width: 1100px)': {
      flexGrow: 1,
    },
    [theme.breakpoints.down('xs')]: {
      flexBasis: '100%',
    },
  },

  linkWrapper: {
    // marginLeft: 'auto',
    '&:visited': {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginRight: 'auto',
    },
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
}));

export default Header;
