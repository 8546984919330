import { gql } from '@apollo/client';

export const GET_USER = gql`
  query user {
    me {
      id
      firstName
      lastName
      fullName
      email
      defaultShippingAddressId
      defaultBillingAddressId
      addresses {
        id
        name
        address1
        city
        stateId
        stateName
        countryId
        countryName
        isPrimary
      }
      claims {
        id
        action
        actionId
        subject
        subjectId
        field
        fieldId
        isCan
        isPassThrough
      }
    }
    session {
      isImpersonated
    }
  }
`;
