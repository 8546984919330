import React from 'react';
import { useQuery } from '@apollo/client';
import { formatCurrency } from 'utils';

import { FETCH_APP_DATA } from 'operations/queries/getAppData';
interface Props {
  // The value to be formatted as currency
  value: number | undefined;
}

const Currency = (props: Props) => {
  // const { data, loading } = useQuery(FETCH_APP_DATA);
  // if (loading) {
  //   return <>loading...</>;
  // }

  const currencyFormatter = formatCurrency({
    // currency: data?.settings?.currency || 'usd',
    currency: 'usd',
  });
  return <>{currencyFormatter.format(props.value || 0)}</>;
};

export default Currency;
