import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import isFunction from 'lodash/isFunction';
import { Input } from 'components/FormElements';

// #region Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


// #endregion Material-UI

import { messageMutations } from 'operations/mutations/messages';
import ConfirmationMessage from 'components/GlobalMessages/components/Messages/ConfirmationMessage';
import { useSaveCart } from 'operations/mutations/saveCart';
import { useHistory } from 'react-router-dom';

const schema = Yup.object({
  name: Yup.string()
    .required('Please enter list name.')
    .min(4, 'Must be at least 4 characters long!')
    .max(10, 'Must be less than 10 characters!')
    .trim('The cart name cannot include leading and trailing spaces')
    .strict(true)
});

interface CreateMyListControls {
  name: string;
  description: string;
}

interface Props {
  handleCancel: () => void;
  handleSuccess: (form: CreateMyListControls) => void;
  hasItems?:boolean;
}

const SaveNewCartForm = (props: Props) => {
  const [openWarningMessage, setWarningMessage] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { mutate:saveCart } = useSaveCart();

  const handleCancel = () => {
    if (isFunction(props.handleCancel)) {
      props.handleCancel();
    }
  };



  const handleSaveCart = (formValues: any) => {
    let title = { title:formValues.values.name }

  if(!props.hasItems){
      messageMutations.addLowAttentionMessage(
        t('savedCarts.save.fail.empty'),
        'alert',
        'error'
      );
      history.push('/')
    }else {
      saveCart({variables:title})
      .then(() => {
        messageMutations.addLowAttentionMessage(
          t('savedCarts.save.success'),
          'alert',
          'success'
        );
        history.push('/account/saved-carts')
      })
      .catch((error)=>{
        messageMutations.addLowAttentionMessage(
          t('savedCarts.save.fail'),
          'alert',
          'error'
        );
      })
    }
    setWarningMessage(false)
    props.handleCancel();
  };

  return (
    <Formik
      initialValues={{
        name:'',
      }}
      onSubmit={(values: any) => {
        setWarningMessage(true)
      }}
      validationSchema={schema}
    >
      {(props: FormikProps<any>) => (
        <Form noValidate>
          <Input
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="List Name"
            type="text"
            fullWidth
          />
          <div className={classes.buttons}>
            <div className={classes.buttonSpacer} />
            <Button onClick={handleCancel} color="primary">
              {'Cancel'}
            </Button>
            <Button type="submit" color="primary">
              {'Save cart'}
            </Button>
          </div>
          {openWarningMessage ? 
            <ConfirmationMessage
              severity="warning"
              handleYes={() => {
                handleSaveCart(props)

              }}
              handleNo={() => setWarningMessage(false)}
              >
              {t('savedCarts.save.warning')}
            </ConfirmationMessage> 
          : null}
        </Form>
      )}
    </Formik>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonSpacer: {
    flex: '1 0 0px',
  },
  buttons: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

export default SaveNewCartForm;
