import { gql, useQuery } from '@apollo/client';


  
export const GET_SAVED_CARTS = gql`
  query getSavedCarts {
    getSavedCarts {
      nodes {
        id
        name
      }
      recordCount
      pageInfo {
        skip
        top
      }
    }
  }
`;



export const useGetSavedCarts = () => {
    const { data, loading, error } = useQuery<any>(GET_SAVED_CARTS, { fetchPolicy: 'network-only' });
  
    return { data, loading, error };
};
  