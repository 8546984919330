import React, { useEffect, useState } from 'react';
import { setAuthInfo, tryRefreshToken } from 'utils/auth';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeOverride } from './interfaces';
import themeBuilder from './buildMuiTheme';
import Routes from './routes';

interface Props {
  isLoggedIn: boolean;
  settings: {
    themeOverrides: ThemeOverride[];
    theme: any;
    siteName: string;
    themeName: string;
    resultsPerPage: string;
    currency: string;
  };
  previewTheme: any;
}

export const history = createBrowserHistory();

const App: React.FC<Props> = (props) => {
  const [theme] = useState(
    themeBuilder(props.previewTheme, props.settings.themeOverrides || [])
  );

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{props.settings.siteName}</title>
        <meta name="description" content="This is my page description" />
      </Helmet>
      <Router>
        <Routes />
        <CssBaseline />
      </Router>
    </ThemeProvider>
  );
};

export default App;
