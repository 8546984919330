import {gql} from "@apollo/client";


export const FETCH_SESSION = gql`
    query fetchSession {
        session {
            id
            cartId
            isImpersonated
            soboUser {
                accountId
                contactId
                email
                firstName
                lastName
            }
        }
    }
`;

export interface SessionData {
    session: Session
}

export interface Session {
    cartId: string | number
    isImpersonated: string | boolean
    soboUser: SoboUser
}

export interface SoboUser {
    accountId: string | number
    contactId: string | number
    email: string
    firstName: string
    lastName: string
    aspNetUserId: number
}