import {gql} from "@apollo/client";


export const GET_LINKED_ACCOUNTS = gql
    `
        query getLinkedAccounts($skip:Int, $searchText: String)
        {
            getLinkedAccounts(skip:$skip, searchText: $searchText) {
                linkedAccounts {
                    id
                    userId
                    fullName
                    code
                    email
                    phoneNumber
                }
                recordCount
            }
        }
    `

export interface GetLinkedAccountsData {
    getLinkedAccounts: LinkedAccountsData

}

export interface LinkedAccountsData {
    linkedAccounts: LinkedAccount[]
    recordCount: number
}

export interface LinkedAccount {
    code: string,
    email: string | null
    fullName: string
    id: string
    phoneNumber: string | null
    userId: number | null
}