import { gql } from '@apollo/client';

export const productFragment = gql`
  fragment ProductFragment on Product {
    name
    seoName
    id
    listPrice
    price
    isInCart @client
    shortDescription
    isDimensionedProduct
    isEligible
    defaultProductId
    isSerialized
    Available
    originCode
    productEntityCode
    selectedDimensions {
      id
      name
      value
    }
    availableOptions { 
      attributeId
      valueId
      name
      value
    }
    # primaryVariantOptions {
    #   id
    #   name
    #   value
    #   cdnURL
    # }
    # variants {
    #   Attributes {
    #     ValueId
    #     Value
    #     Name
    #   }
    # }
    img {
      id
      cdnURL
      alt
    }
  }
`;
