import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import {Social} from "./Social";
import {IconButton,Link} from "@material-ui/core";

interface Props {
    social: Social;
}

export const SocialSharing:React.FC<Props> = ({social}) => {
    const classes = useStyles();
    return (
        <>
            <Typography variant="h5" className={classes.title}>
                {social.socialHeading}
            </Typography>
            <Box display="flex" className={classes.socialIcons}>
                {social.instagramLink &&
                <IconButton href={social.instagramLink} target={"_blank"} aria-label={'Link to Instagram'}>
                    <InstagramIcon fontSize="large"/>
                </IconButton>}
                {social.youtubeLink &&
                <IconButton href={social.youtubeLink} target={"_blank"} aria-label={'Link to Youtube'}>
                    <YouTubeIcon fontSize="large"/>
                </IconButton>}
                {social.facebookLink &&
                <IconButton href={social.facebookLink} target={"_blank"} aria-label={'Link to Facebook'}>
                    <FacebookIcon fontSize="large"/>
                </IconButton>}
                {social.twitterLink &&
                <IconButton href={social.twitterLink} target={"_blank"} aria-label={'Link to X'}>
                    <img src="/x.png" />
                </IconButton>}
                {social.tiktokLink &&
                <IconButton href={social.tiktokLink} target={"_blank"} aria-label={'Link to TikTok'}>
                    <img src="/tiktok.png" />
                </IconButton>}
            </Box>
            <Box display="flex" className={classes.customerService}>
                {/* <Link href="https://prsguitars.com/support" target={"_blank"} aria-label={'Link to Customer Service'}>
                    <Typography>
                        Contact Customer Service
                    </Typography>
                </Link> */}
                <Button color={'primary'} variant={'contained'} href="https://prsguitars.com/support" target={"_blank"} aria-label={'Link to Customer Service'}>
                    Contact Customer Service
                </Button>
            </Box>
        </>
    )
}


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        marginTop: theme.spacing(1),
        borderTopColor: theme.palette.primary.light,
        paddingTop: theme.spacing(2),
        justifyContent: 'center'
    },
    paper: {
        color: theme.palette.text.secondary
    },
    title: {
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left'
        }
    },
    socialIcons: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        },
    },
    customerService: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            marginLeft: '0px',
            paddingBottom: '12px'
        },
        textAlign: 'left',
        justifyContent: 'left',
        marginLeft: '12px'
    },
    menuItem: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
    link: {
        color: theme.palette.text.primary
    },
    contactContainer: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    }
}));