import React, { Suspense } from 'react';
import { Route, Redirect } from 'react-router-dom';
import noop from 'lodash/noop';
import GlobalLoading from 'components/GlobalLoading';
import { useQuery } from '@apollo/client';
import { IS_LOGGED_IN } from 'operations/queries/isLoggedIn';

import DefaultLayout from 'pages/_layouts/default';
import AuthLayout from 'pages/_layouts/auth';
import InnerLayout from 'pages/_layouts/inner';
import CheckoutLayout from 'pages/_layouts/checkout';

const layouts = new Map();
layouts.set('default', DefaultLayout);
layouts.set('auth', AuthLayout);
layouts.set('inner', InnerLayout);
layouts.set('checkout', CheckoutLayout);

interface Props {
  component: any;
  isPrivate?: boolean;
  path?: string;
  exact?: boolean;
  layout?: string;
  location?: any;
  getPropsFromPage?: (title: string) => void;
  pageData?: any;
  requiresUnauthenticated?: boolean;
}

const RouteWrapper: React.FC<Props> = ({
  component: Component,
  isPrivate,
  requiresUnauthenticated,
  layout,
  location,
  getPropsFromPage,
  pageData,
  ...rest
}: Props) => {
  const { data, loading, error } = useQuery(IS_LOGGED_IN);
  // const authenticated = currentUser && currentUser.exp > Date.now() / 1000;
  const authenticated = data?.isLoggedIn;
  /** Redirect user to Login page if he tries to access a private route
   * without authentication
   */
  if (isPrivate && !authenticated) {
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  } else if (requiresUnauthenticated && authenticated) {
    return <Redirect to={{ pathname: '/', state: { from: location } }} />;
  }

  let Layout = AuthLayout;
  if (layout && layouts.has(layout)) {
    Layout = layouts.get(layout);
  }

  // let Cmp: any = import(`pages/${Component}`);
  const LoadingMessage = () => <GlobalLoading delay={0}>Loading</GlobalLoading>;
  const loadComponent = () => {
    if (typeof Component === 'string') {
      return React.lazy(() => import(`pages/${Component}`));
    } else {
      return Component;
    }
    
  };
  return (
    // <Route 
    //   {...rest} 
    //   render={(props) => (
    //     <Layout>
    //       <Component {...props} pageData={pageData} getPropsFromPage={() => {}} />
    //     </Layout>
    //   )} />
    <Route
      {...rest}
      render={(props) => {
        let C: any = loadComponent() || null;

        return (
         
            <Layout>
               <Suspense fallback={<LoadingMessage />}>
              {C && (
                <C {...props} pageData={pageData} getPropsFromPage={getPropsFromPage} />
              )}
              </Suspense>
            </Layout>
          
        );
      }}
    />
  );
};

RouteWrapper.defaultProps = {
  getPropsFromPage: noop,
};
export default RouteWrapper;
