import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Copyright from 'components/Footer/Copyright';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(2, 'auto'),
    // marginTop: theme.spacing(22),
    // [theme.breakpoints.up('md')]: {
    //   marginTop: theme.spacing(17),
    // },
    flex: '1 0 0px',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    // display: 'flex',
    // flexDirection: 'column',
    // // minHeight: '80vh',
    // flex: '1 0 100%',
  },
}));

interface Props {
  children: React.ReactNode;
}

const AuthLayout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <>
        <Header menuJson={null} />
      <Container className={classes.root} maxWidth="lg">
        <main className={classes.main}>{children}</main>
      </Container>
      <footer>
        <Footer />
        <Copyright />
      </footer>

    </>
  );
};

export default AuthLayout;
