import { ReactiveVar } from '@apollo/client';

import { AttentionMessageVariant } from 'interfaces/messages';

export default function addAddToCartMessage(messagesVar: ReactiveVar<any>) {
  const createNewMessageId = (allMessages: any) => (
    allMessages.reduce(
      (maxId: number, todo: any) => Math.max(todo.id, maxId),
      -1,
    ) + 1
  );

  const createNewMessage = (
    name: string,
    img: any[],
    attributes: AttentionMessageVariant[],
    price: number,
    quantity: number,
    seoName: string,
    allMessages: ReactiveVar<any>,
  ) => ({
    name,
    img,
    attributes,
    price,
    quantity,
    seoName,
    id: createNewMessageId(allMessages),
  });

  return (
    name: string,
    img: any[],
    attributes: AttentionMessageVariant[],
    price: number,
    quantity: number,
    seoName: string,
  ) => {
    const allMessages = messagesVar();
    messagesVar([
      createNewMessage(
        name,
        img,
        attributes,
        price,
        quantity,
        seoName,
        allMessages,
      ),
    ]);
  };
}
