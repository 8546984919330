import React from 'react';
import { useTranslation } from 'react-i18next';
// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import {SocialSharing} from "./SocialSharing";
import {Social} from "./Social";
import {Button} from "@material-ui/core";

interface Props {}

const Footer: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const social: Social = {
    socialHeading: 'Connect with PRS',
    instagramLink: 'https://www.instagram.com/prsguitars',
    youtubeLink: 'https://youtube.com/prsguitars',
    facebookLink: 'https://www.facebook.com/prsguitars',
    twitterLink: 'https://twitter.com/prsguitars',
    tiktokLink: 'https://www.tiktok.com/@prsguitars',
    customerServiceLink: 'https://prsguitars.com/support'
    
  }
  return (
    <div className={classes.root}>
      <Container disableGutters>
        <Grid container className={classes.grid} alignContent="center">
          <Grid item xs={12} sm={4}>
            <SocialSharing social={social} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={classes.group}>
              <Typography variant="subtitle1" className={classes.heading}>
                Our Company
              </Typography>
              <ul className={classes.linkGroup}>
                <li>
                  <Link href="https://prsguitars.com/about" color="inherit" target={"_blank"}>
                    About PRS
                  </Link>
                </li>
                <li>
                  <Link href="https://prsguitars.com/events" color="inherit" target={"_blank"}>
                    Events
                  </Link>
                </li>
                <li>
                  <Link href="https://prsguitars.com/register" color="inherit" target={"_blank"}>
                    Register Your PRS
                  </Link>
                </li>
                <li>
                  <Link href="https://prsguitars.com/mediaroom" color="inherit" target={"_blank"}>
                    Media Room
                  </Link>
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={classes.group}>
            <Typography variant="subtitle1" className={classes.heading}>
                More Information
              </Typography>
              <ul className={classes.linkGroup}>
                <li>
                  <Link href="https://prsguitars.com/copyright" color="inherit" target={"_blank"}>
                    &copy; PRS Guitars 2024
                  </Link>
                </li>
                <li>
                  <Link href="https://prsguitars.com/privacy" color="inherit" target={"_blank"}>
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.grey[100],
  },
  grid: {
    padding: theme.spacing(3),
    boxSizing: 'border-box',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0, 3, 0),
    },
  },
  heading: {
    fontWeight: 'bold',
  },
  group: {
    display: 'inline-block',
    textAlign: 'left',
  },
  linkGroup: {
    listStyleType: 'none',
    padding: 0,
    color: theme.palette.text.primary,
    '& > li': {
      color: theme.palette.text.secondary,
      margin: theme.spacing(1, 0),
      '& > a:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
}));

export default Footer;
