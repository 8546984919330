import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { useQuery, useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { clearToken, getAuthToken } from 'utils/auth';
import { GET_USER } from 'operations/queries/getUser';
import { user } from 'operations/queries/__generated__/user';
import { useAbilityDispatch } from 'utils/AbilityContext';

// import { LOGOUT } from 'operations/mutations/logout';
import { useLogout } from 'operations/mutations/logout';

// #region Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// #endregion Material-UI

const UserSession = () => {
  const classes = useStyles();
  const { data, client } = useQuery<any>(GET_USER, {
    skip: !getAuthToken(),
  });
  //   const [logout] = useMutation(LOGOUT);
  const { mutate: logout } = useLogout();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation();
  const abilityDispatch = useAbilityDispatch();


  useEffect(() => {
    if(data)
      abilityDispatch({ type: "SET_CLAIMS", payload: data?.me?.claims })
  },[data]);

  const open = Boolean(anchorEl);

  const handleLogOut = async (
    event: React.MouseEvent<HTMLLIElement | MouseEvent>
  ) => {
    setAnchorEl(null);

    try {
      await logout();
      await client.cache.reset();
      clearToken();
    } catch (error) {
      console.log('Unable to clear cache ', error);
    }
  };

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {data && data.me ? (
        <Button
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          variant="text"
          color="default"
          startIcon={<AccountCircle />}
        >
          <Hidden smDown>
            {data.me &&
              t('welcomeMessage.text', {
                firstName: data.me.firstName,
                lastName: data.me.lastName,
                userName: data.me.email,
              })}
          </Hidden>
        </Button>
      ) : (
        <Button component={Link} to="/login">
          Login/Register
        </Button>
      )}

      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={handleClose}>
          <Link to="/account" className={classes.menuLink}>
            My Account
          </Link>
        </MenuItem>
        <MenuItem onClick={handleLogOut}>
          <Link to="/" className={classes.menuLink}>
            Log Out
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  menuLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));

export default UserSession;
