import React from 'react';

export const addDefaultImage = (
  event: React.SyntheticEvent<HTMLImageElement>,
) => {
  event.currentTarget.src = '/no-image-40.png';
  event.currentTarget.onerror = null;
};

/**
 * 
 * @param cartLine Object
 * @returns {boolean} If product code is composed of 3 parts we detect is as a serialized product.
 * TODO: A more robust approach should be added to use ProductType
 */
export const isSerializedItem = (cartLine: any) => {
  return cartLine?.code?.split('-').length === 3;
}