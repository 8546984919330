import { gql, useMutation } from '@apollo/client';
import { GET_CART } from 'operations/queries';
import { GET_SAVED_CARTS } from 'operations/queries/getSavedCarts';


export const SAVE_CART = gql`
    mutation saveCart($title:String!){
        saveCart(title:$title){
        code
        success
        message
        }
    }
`;

//Change schema to update cache automaticly
export const useSaveCart = () => {
    const [mutate, { data, error }] = useMutation<any>(SAVE_CART,{refetchQueries:[{query:GET_SAVED_CARTS},{query:GET_CART}]});
  
    return { mutate, data, error };
  };
  