import { gql } from '@apollo/client';

import {
  addressFragment,
  cartLinesFragment,
  orderAddressFragment,
} from '../fragments';

export const GET_CHECKOUT_PAGE = gql`
    query checkout {
        me {
            id
            firstName
            lastName
			email
            claims {
                id
                action
                actionId
                subject
                subjectId
                field
                fieldId
                isCan
                isPassThrough
            }
			flooringProgramLines {
				id
				name
				code
				minSalesAmount
				isSerialized
			}
        }
        cart {
            id
            itemCount
            subtotal
            total
            messages {
                type
                text
            }
            shippingMethodId
            taxTotal
            freightTotal
            email
            orderShippingAddressId
            orderBillingAddressId
            lines {
                ...CartLinesFragment
            }
        }
        getCartAddresses {
            ...AddressFragment
        }
    }
    ${cartLinesFragment},
    ${addressFragment}
`;
