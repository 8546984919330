import React, {ChangeEvent, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import ProductCompact from 'components/ProductList/productCompact';
import {debounce} from 'throttle-debounce';

// #region Material-UI
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Link from '@material-ui/core/Link';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {gql, useLazyQuery} from "@apollo/client";
import {Value} from "@material-ui/lab";

// #endregion Material-UI

interface Props {
  toggleSearchMenu(isOpen: boolean): void;
  isOpen: boolean;
  // If set to true the search component will support typeahead
  // It will try to get the list of products from an API
  // based on entered text.
  // Default: false
  enableTypeahead?: boolean;
}

const tempSearhcQuery = gql`
  query searchPage($keyword: String) {
    productsTypeAhead(input:{search:$keyword, top: 5}) {
      id
      name
      listPrice
      price
      seoName
      price
      img {
        id
        cdnURL
        alt
      }
    }
  }
`

const Search: React.FC<Props> = ({
  isOpen,
  toggleSearchMenu,
  enableTypeahead,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [executeQuery , { data , loading , error}] = useLazyQuery(tempSearhcQuery);
  const [list, setList] = useState<any[]>([]);
  const urlParams = new URLSearchParams(location.search);
  const [value, setValue] = useState(
    urlParams.has('keyword') ? urlParams.get('keyword') || '' : '',
  );

  React.useEffect(() => {
    if (!open) {
      setList([]);
    }
  }, [open]);

  React.useEffect(() => {
    if (data?.productsTypeAhead) {
      setList(data.productsTypeAhead);
    }
  }, [data])


  const advancedSearch = (event: React.SyntheticEvent<HTMLElement>) => {
    event.preventDefault();
    history.push(`/search?keyword=${encodeURIComponent(value)}`);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let v = event.target.value
      .replace('-', ' ')
      .replace('/', ' ')
      .replace('\\', ' ')
      .replace('(', ' ')
      .replace(')', ' ')
      .replace('[', ' ')
      .replace(']', ' ')
      .replace('&', ' ')
      .replace('"', ' ')
    setValue(v);
    debouncedTypeaheadSearch(v);
  };

  const debouncedTypeaheadSearch = debounce(300 , (keyword: string) => {
    executeQuery({variables: {keyword}});
  })

  const handleGoToProduct = (e: ChangeEvent<{}>, value: Value<unknown, undefined, undefined, undefined>) => {

    if (typeof value === 'object' && value != null) {
      const {seoName} = value as any;
      history.push(`/search?keyword=${encodeURIComponent(seoName)}`)
    }

  }



  return (
    <>
      {isOpen ? (
        <>
          <Slide in direction="left" mountOnEnter unmountOnExit>
            <Paper
              component="form"
              className={classes.root}
              onSubmit={advancedSearch}
            >
              <Autocomplete
                id="search-products"
                className={classes.autocomplete}
                open={data?.typeAheadProducts?.length}
                // onOpen={() => {
                //   if (enableTypeahead) {
                //     setOpen(true);
                //   }
                // }}
                // onClose={() => {
                //   if (enableTypeahead) {
                //     setOpen(false);
                //   }
                // }}
                options={list}
                freeSolo
                autoSelect={false}
                filterSelectedOptions={false}
                getOptionLabel={(option) => option.name || ''}
                includeInputInList
                loadingText="Loading..."
                loading={loading}
                onChange={(e, value) => handleGoToProduct(e, value)}
                // noOptionsText={value && !loading ? "No results available" : "Loading..."}
                classes={{
                  popper: classes.popper,
                  option: classes.option,
                }}
                renderInput={(params) => (
                  <InputBase
                      autoFocus={true}
                    id={params.id}
                    disabled={params.disabled}
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    className={classes.input}
                    fullWidth
                    onChange={handleChange}
                    placeholder="Search Products"
                    endAdornment={(
                      <>
                        <div
                          style={{
                            width: '25px',
                          }}
                        >
                          {loading ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                        </div>
                      </>
                      )}
                  />
                )}
                renderOption={(product) => (
                  <Link
                      // onKeyUp={() => handleGoToProduct(product.id)}
                    key={product.id}
                    // href={`/search?keyword=${encodeURIComponent(value)}`}
                    className={classes.product}
                    color="textPrimary"
                    underline="none"
                  >
                    <ProductCompact product={product}/>
                  </Link>
                )}
              />
              <IconButton
                type="submit"
                onClick={advancedSearch}
                aria-label={'Search'}
              >
                <SearchIcon titleAccess="search"/>
              </IconButton>
              <Divider orientation="vertical" />
              <IconButton
                aria-label="Close"
                edge="end"
                onClick={() => toggleSearchMenu(false)}
              >
                <CloseIcon />
              </IconButton>
            </Paper>
          </Slide>
        </>
      ) : (
        <div className={classes.icon}>
          <IconButton color="inherit" onClick={() => toggleSearchMenu(true)} aria-label={'search'}>
            <SearchIcon />
          </IconButton>
        </div>
      )}
    </>
  );
};

Search.defaultProps = {
  enableTypeahead: false,
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    padding: '2px 10px',
    display: 'flex',
    alignItems: 'center',
    width: '45%',
  },
  autocomplete: {
    flex: 1,
  },
  popper: {
    [theme.breakpoints.down('xs')]: {
      minWidth: '97%',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 400,
    },
  },
  option: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:first-child': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    borderWidth: 0,

  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 15,
    margin: 4,
  },
  product: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    margin: 0,
  }, icon: {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    cursor: 'pointer',
    margin: theme.spacing(0.5),
    boxSizing: 'content-box',
},
}));

export default Search;
