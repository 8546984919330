import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// Components
import Currency from 'components/Currency';

// Icons
import DeleteIcon from '@material-ui/icons/Delete';

// Material-UI
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';

// Interfaces
interface Dimension {
	id: string;
	name: string;
	value: string;
}
interface CartLine {
	productId: string;
	quantity: number;
	amount: number;
	code: string;
	name: string;
	listPrice: number;
	discount: number;
	img: Array<{
		id: string;
		alt: string;
		cdnURL: string;
	}>;
	selectedDimensions: Dimension[];
}


interface Props {
	className?: string;
	cartLine: CartLine;
	onDelete: (cartLine: CartLine) => void;
	setState: Function;
};

const ProductMiniCartItem: React.FC<Props> = (props) => {
	const classes = useStyles();

	const handleClick = () => {
		props.onDelete(props.cartLine as any);
	};

	return (
		<div className={`${classes.root} ${props.className || ''}`}>
			{/* <Link component={RouterLink} to={`/product-detail/${props.cartLine.code}`} onClick={() => props.setState({ miniCartOpen: false })}> */}
				<div className={classes.image}>
					<img
						src={props.cartLine?.img[0]?.cdnURL || ''}
						alt={`${props.cartLine.name}image`}
						onError={(event) => {
							event.currentTarget.src = '/no-image-40.png';
						}}
					/>
				</div>
			{/* </Link> */}

			<div className={classes.details}>
				{/* <Link
					component={RouterLink}
					to={`/product-detail/${props.cartLine.code}`}
					color="inherit"
					onClick={() => {
						props.setState({ miniCartOpen: false });
					}}
				> */}
				<Typography variant="h6" gutterBottom>{props.cartLine.name}</Typography>

				{/* </Link> */}
				<div className={classes.qtyPrice}>
					<div className={classes.label}>Qty:</div>
					<Typography variant="body2" color="secondary" className={classes.price}>{props.cartLine.quantity}</Typography>
					<div style={{ margin: '0px 8px' }}>x</div>
					<div className={classes.label}>Price:</div>

					<Typography variant="body2" color="secondary" className={classes.price}>
						{props.cartLine.discount == 0 || props.cartLine.discount == undefined ? (
							<Currency value={props.cartLine.listPrice} />
						):(
							<>
								<s><Currency value={props.cartLine.listPrice} /></s>
								<Currency value={props.cartLine.listPrice - props.cartLine.discount} />
							</>
						)}
					</Typography>
				</div>
				<div>
					{props?.cartLine?.selectedDimensions?.map((dimension: Dimension) => (
						<Typography key={dimension.id} variant="body2" component="p"><b>{dimension.name}:</b>&nbsp; {dimension.value}</Typography>
					))}
				</div>
				<div>
					<IconButton size="small" onClick={handleClick}>
						<DeleteIcon fontSize="small" />
					</IconButton>
				</div>
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2, 1),
		display: 'flex',
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	details: {
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
	},
	image: {
		paddingTop: 80,
		width: 80,
		position: 'relative',
		height: 0,
		marginRight: theme.spacing(1),
		'& > img': {
			position: 'absolute',
			top: 0,
			left: 0,
			height: '100%',
			width: '100%',
			objectFit: 'contain',
		},
	},
	price: {
		fontWeight: 'bold',
	},
	stretch: {
		flexGrow: 1,
	},
	qtyPrice: {
		display: 'flex',
		alignItems: 'center',
	},
	label: {
		marginRight: theme.spacing(1),
		fontWeight: 600,
	},
}));

export default ProductMiniCartItem;
